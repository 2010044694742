<template>
  <v-row
    v-if="curentBlog.contentID"
    justify="center"
    style="padding-bottom: 100px"
  >
    <v-col cols="auto">
      <Images
        @click.native="curentPost"
        width="100%"
        class="pointer"
        :name="curentBlog.fields.headerImage.url"
        :alt="curentBlog.fields.headerImage.label"
      />
    </v-col>
    <v-row justify="center">
      <v-col cols="10">
        <v-row align="center" justify="start">
          <v-col
            cols="auto"
            class="blog-page-post-header"
            style="padding-top: 60px; padding-bottom: 9px"
          >
            <Heading
              type="h2-4 MainDark"
              @click.native="curentPost"
              class="blog-header pointer"
              >{{ curentBlog.fields.header }}</Heading
            >
          </v-col>
          <div style="width: 100%"></div>
          <v-col cols="auto" style="padding-top: 0">
            <Texting
              type="p3small blackColor"
              @click.native="curentPost"
              class="pointer"
              >{{ curentBlog.fields.description }}</Texting
            >
          </v-col>
          <div style="width: 100%"></div>
          <v-col cols="auto">
            <Texting type="p2strong asphalt">{{
              getDateText(curentBlog.fields.date)
            }}</Texting>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <div class="circle-icon"></div>
          </v-col>
          <v-col cols="auto">
            <Texting type="p2strong asphalt">{{
              curentBlog.fields.readingTime
            }}</Texting>
          </v-col>
          <v-divider style="border: none"></v-divider>
          <v-col cols="auto">
            <Button
              :id="'blog-continue-reading' + curentBlog.contentID"
              size="small"
              style="border-radius: 20px !important"
              class="get-started"
              type="secondary"
              texting="p1strong grey10"
              :handleClick="curentPost"
              >Continue Reading
              <Images name="post_arrow.svg" style="margin-left: 7px" />
            </Button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import Images from "@/components/MyImages";
import Heading from "@/components/Heading";
import Texting from "@/components/Texting";
import Button from "@/components/Button";
import myServices from "../services/resource";

export default {
  components: {
    Button,
    Texting,
    Heading,
    Images,
  },
  props: {
    curentBlog: Object,
  },
  async mounted() {
    let event = new Event("renderer-ready");
    window.document.dispatchEvent(event);
  },
  methods: {
    getDateText(date) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let month = monthNames[new Date(date).getMonth()];
      let day = new Date(date).getDate();
      let year = new Date(date).getFullYear();
      return month + " " + day + ", " + year;
    },
    curentPost: function () {
      myServices.myAnalyticsEvents(
        this,
        "Blog",
        "Article- " + this.curentBlog.fields.header,
      );
      if (
        typeof this.curentBlog.fields.postContent_ValueField !== "undefined"
      ) {
        window.location.href =
          window.location.href + "/" + this.curentBlog.fields.urlkeywords;
      }
    },
  },
};
</script>

<style scoped>
.blog-header {
  text-shadow: 0 17px 75px rgba(0, 0, 0, 0.07),
    0 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
    0 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
    0 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
    0 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
}

.circle-icon {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #8e9ca3;
}
@media screen and (max-width: 599px) {
  .blog-page-post-header {
    padding-top: 10px !important;
  }
}
</style>
