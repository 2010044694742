<template>
  <div>
    <AppHeader />
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="10" style="max-width: 1104px">
          <div
            v-for="blog in reversed"
            :id="'blog_post_' + blog.contentID"
            :key="blog.contentID"
          >
            <Blog :curentBlog="blog" />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <AppFooter />
  </div>
</template>

<script>
import api from "@/services/agility/agility.client";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import Blog from "@/components/Blog";
export default {
  components: {
    Blog,
    AppFooter,
    AppHeader,
  },
  data: () => ({
    posts: [],
    reversed: [],
  }),
  async mounted() {
    //use async to write cleaner code
    let contentListResult = await api.getContentList({
      referenceName: "BlogPosts",
      languageCode: "en-us",
    });
    this.posts = contentListResult.items;
    this.reversed = this.posts.sort((a, b) => {
      if (a.contentID < b.contentID) {
        return 1;
      } else {
        return -1;
      }
    });
  },
};
</script>
